<template>
  <v-icon class="ml-1 mr-3" :color="color">{{icon}}</v-icon>
</template>

<script>

export default {
  props: {
    value: Boolean,
  },
  data () {
    return {
    }
  },
  computed: {
    color() { return this.value ? "green" : "red"},
    icon() { return this.value ? "mdi-check-circle" : "mdi-close-circle"}
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {
  }
}

</script>
